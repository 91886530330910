import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { projects } from '../data/projects';
import { useTranslation } from 'react-i18next';

function ProjectDetail() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { id } = useParams();
  const navigate = useNavigate();
  const project = projects.find(p => p.id === parseInt(id));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    // Sayfa yüklendiğinde yukarı kaydır
    window.scrollTo(0, 0);
  }, [id]);

  // Features ve Roles için ayrı bir useEffect
  useEffect(() => {
    if (project) {
      const hasFeatures = project.features || 
        (project.details?.özellikler && 
         ((typeof project.details.özellikler === 'object' && project.details.özellikler[currentLang]?.length > 0) || 
          (Array.isArray(project.details.özellikler) && project.details.özellikler.length > 0)));

      const hasRoles = project.roles || 
        (project.details?.roller && 
         ((typeof project.details.roller === 'object' && project.details.roller[currentLang]?.length > 0) || 
          (Array.isArray(project.details.roller) && project.details.roller.length > 0)));

      setContentLoading(false);
    }
  }, [project, currentLang]);

  const handleGoBack = () => {
    navigate('/');
    setTimeout(() => {
      const projectsSection = document.getElementById('projects');
      projectsSection?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  if (!project) {
    return navigate('/');
  }

  // Video ve resimleri birleştir (video ilk sırada)
  const allMedia = [];
  if (project.videoUrl) {
    allMedia.push(project.videoUrl);
  }
  allMedia.push(...project.gallery);

  const isCurrentItemVideo = currentImageIndex === 0 && project.videoUrl;

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === allMedia.length - 1 ? 0 : prev + 1
    );
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? allMedia.length - 1 : prev - 1
    );
  };

  // Video thumbnail URL'sini oluşturan yardımcı fonksiyon
  const getVideoThumbnail = (videoUrl) => {
    const videoId = videoUrl.split('/').pop();
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  };

  // Video URL'sini düzenleyen yardımcı fonksiyon
  const getVideoUrl = (url) => {
    const videoId = url.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}?controls=1&rel=0`;
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  // Features render fonksiyonu
  const renderFeatures = () => {
    const features = [];
    
    // Project details özellikler
    if (project?.details?.özellikler) {
      if (typeof project.details.özellikler === 'object') {
        // Önce mevcut dildeki özellikleri dene
        const currentLangFeatures = project.details.özellikler[currentLang];
        // Eğer mevcut dilde özellik yoksa, varsayılan olarak TR veya EN'i kullan
        const defaultFeatures = project.details.özellikler.tr || project.details.özellikler.en;
        
        const featuresToUse = currentLangFeatures || defaultFeatures;
        
        if (Array.isArray(featuresToUse)) {
          features.push(...featuresToUse.map((ozellik, index) => ({
            id: `ozellik-${index}`,
            content: ozellik
          })));
        }
      } else if (Array.isArray(project.details.özellikler)) {
        features.push(...project.details.özellikler.map((ozellik, index) => ({
          id: `ozellik-${index}`,
          content: ozellik
        })));
      }
    }
    
    return features;
  };

  // Roles render fonksiyonu
  const renderRoles = () => {
    const roles = [];
    
    // Project details roller
    if (project?.details?.roller) {
      if (typeof project.details.roller === 'object') {
        // Önce mevcut dildeki rolleri dene
        const currentLangRoles = project.details.roller[currentLang];
        // Eğer mevcut dilde rol yoksa, varsayılan olarak TR veya EN'i kullan
        const defaultRoles = project.details.roller.tr || project.details.roller.en;
        
        const rolesToUse = currentLangRoles || defaultRoles;
        
        if (Array.isArray(rolesToUse)) {
          roles.push(...rolesToUse.map((rol, index) => ({
            id: `rol-${index}`,
            content: rol
          })));
        }
      } else if (Array.isArray(project.details.roller)) {
        roles.push(...project.details.roller.map((rol, index) => ({
          id: `rol-${index}`,
          content: rol
        })));
      }
    }
    
    return roles;
  };

  // Features Section render kontrolü
  const hasFeatures = () => {
    const features = renderFeatures();
    return features.length > 0;
  };

  // Roles Section render kontrolü
  const hasRoles = () => {
    const roles = renderRoles();
    return roles.length > 0;
  };

  return (
    <section className="min-h-screen py-16 bg-gray-900 relative overflow-hidden">
      {/* Arkaplan dekoratif elementleri */}
      <div className="absolute top-0 right-0 w-1/3 h-full bg-gradient-to-l from-blue-500/5 to-transparent"></div>
      <div className="absolute bottom-0 left-0 w-1/3 h-full bg-gradient-to-t from-purple-500/5 to-transparent"></div>
      
      {/* Hareketli dekoratif ışık noktaları */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-10 -left-10 w-40 h-40 bg-purple-500/10 rounded-full blur-3xl animate-blob1"></div>
        <div className="absolute top-1/3 -right-20 w-60 h-60 bg-blue-500/10 rounded-full blur-3xl animate-blob2"></div>
        <div className="absolute bottom-20 left-1/4 w-40 h-40 bg-teal-500/10 rounded-full blur-3xl animate-blob3"></div>
      </div>
      
      {/* Izgara arka plan deseni */}
      <div 
        className="absolute inset-0 opacity-5"
        style={{
          backgroundImage: 'url(/grid.svg)',
          backgroundSize: '30px 30px',
          backgroundRepeat: 'repeat',
        }}
      ></div>
      
      {contentLoading ? (
        <div className="flex items-center justify-center min-h-[80vh]">
          <div className="relative">
            <div className="w-16 h-16 border-4 border-purple-500 border-t-transparent rounded-full animate-spin"></div>
            <div className="absolute inset-0 w-16 h-16 border-4 border-transparent border-b-blue-500 rounded-full animate-pulse opacity-75"></div>
          </div>
        </div>
      ) : (
        <motion.div 
          className="container mx-auto px-4"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <div className="flex items-center justify-between mb-8">
            <motion.button
              onClick={handleGoBack}
              className="text-gray-400 hover:text-white flex items-center gap-2 z-10 relative group transition-all"
              variants={itemVariants}
              whileHover={{ x: -5 }}
              whileTap={{ scale: 0.95 }}
            >
              <span className="absolute inset-0 bg-gradient-to-r from-purple-600/20 to-blue-600/20 rounded-lg blur-lg opacity-0 group-hover:opacity-100 transition-opacity"></span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
              <span>{t('projects.goBack', 'Geri Dön')}</span>
            </motion.button>
            
            {project.gameUrl && (
              <motion.a
                href={project.gameUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="hidden md:flex items-center gap-2 bg-gradient-to-r from-purple-600 to-blue-500 px-4 py-2 rounded-lg text-white font-semibold hover:shadow-lg hover:shadow-purple-500/30 transition-all duration-300 play-button shadow-glass hover:shadow-glass-hover"
                variants={itemVariants}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                </svg>
                {t('projects.playGame', 'Oyunu Oyna')}
              </motion.a>
            )}
          </div>
          
          {/* Proje Başlık Alanı */}
          <motion.div 
            className="text-center mb-8" 
            variants={itemVariants}
          >
            <span className="text-sm text-gray-400 block mb-1 animate-pulse-slow">{t('projects.projectDetail', 'PROJE DETAYI')}</span>
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white text-center mb-6 tracking-tighter">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">
                {typeof project?.title === 'object' ? (project?.title?.[currentLang] || project?.title?.en || project?.title?.tr || "") : project?.title || ""}
              </span>
            </h1>
            <div className="w-24 h-1 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full mx-auto mt-4"></div>
          </motion.div>
          
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Sol Taraf - Galeri ve Video (2/3 Alan) */}
            <motion.div
              className="lg:col-span-2 bg-gray-800/70 backdrop-blur-sm rounded-xl overflow-hidden relative border border-gray-700 shadow-xl shadow-glass"
              variants={itemVariants}
            >
              <div className="relative aspect-video">
                <AnimatePresence mode="wait">
                  {isCurrentItemVideo ? (
                    <motion.div
                      key="video"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="w-full h-full relative z-0 bg-gradient-glass"
                    >
                      <iframe
                        src={getVideoUrl(allMedia[currentImageIndex])}
                        title={`${project.title} Gameplay`}
                        className="w-full h-full"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </motion.div>
                  ) : (
                    <motion.div
                      key={`img-${currentImageIndex}`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="bg-black w-full h-full flex items-center justify-center bg-gradient-radial from-gray-800 to-gray-900"
                    >
                      <img
                        src={allMedia[currentImageIndex]}
                        alt={`${project.title} - Görsel ${currentImageIndex}`}
                        className="max-w-full max-h-full object-contain"
                        loading="eager"
                        decoding="async"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="relative p-4 bg-gradient-glass backdrop-blur-sm">
                <div className="flex flex-col md:flex-row items-center gap-4">
                  <motion.button
                    onClick={previousImage}
                    className="p-3 rounded-full bg-gray-800 text-gray-400 hover:text-white border border-gray-700 hover:border-purple-500/50 hover:shadow-lg hover:shadow-purple-500/20 transition-all duration-300"
                    whileHover={{ scale: 1.1, rotate: -5 }}
                    whileTap={{ scale: 0.95 }}
                    aria-label="Önceki görsel"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-5 w-5" 
                      viewBox="0 0 20 20" 
                      fill="currentColor"
                    >
                      <path 
                        fillRule="evenodd" 
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" 
                        clipRule="evenodd" 
                      />
                    </svg>
                  </motion.button>
                  
                  <div className="flex-1 overflow-x-auto hide-scrollbar">
                    <div className="grid grid-flow-col auto-cols-max gap-2">
                      {allMedia.map((item, index) => (
                        <motion.div
                          key={index}
                          className={`w-16 h-16 md:w-20 md:h-20 rounded-lg overflow-hidden cursor-pointer relative ${
                            currentImageIndex === index ? 'ring-2 ring-purple-500 shadow-inner-glow' : ''
                          }`}
                          onClick={() => setCurrentImageIndex(index)}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.98 }}
                          animate={{ y: [0, index % 2 === 0 ? -3 : 3, 0] }}
                          transition={{ 
                            y: { 
                              repeat: Infinity, 
                              duration: 3 + index * 0.5, 
                              ease: "easeInOut" 
                            }
                          }}
                        >
                          {index === 0 && project.videoUrl ? (
                            <div className="relative w-full h-full">
                              <img
                                src={getVideoThumbnail(project.videoUrl)}
                                alt={`${project.title} - Video Önizleme`}
                                className="w-full h-full object-cover"
                                loading="lazy"
                                decoding="async"
                              />
                              <div className="absolute inset-0 flex items-center justify-center">
                                <div className="relative group">
                                  <div className="bg-black/50 backdrop-blur-sm p-1.5 rounded-full transform group-hover:scale-110 transition-all duration-300 border border-white/10 animate-glow">
                                    <svg 
                                      xmlns="http://www.w3.org/2000/svg" 
                                      viewBox="0 0 24 24" 
                                      fill="currentColor" 
                                      className="w-4 h-4 text-white group-hover:text-purple-300 transition-colors"
                                    >
                                      <path 
                                        fillRule="evenodd" 
                                        d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" 
                                        clipRule="evenodd" 
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <img
                              src={item}
                              alt={`${project.title} - Küçük Görsel ${index}`}
                              className="w-full h-full object-cover"
                              loading="lazy"
                              decoding="async"
                            />
                          )}
                        </motion.div>
                      ))}
                    </div>
                  </div>

                  <motion.button
                    onClick={nextImage}
                    className="p-3 rounded-full bg-gray-800 text-gray-400 hover:text-white border border-gray-700 hover:border-purple-500/50 hover:shadow-lg hover:shadow-purple-500/20 transition-all duration-300"
                    whileHover={{ scale: 1.1, rotate: 5 }}
                    whileTap={{ scale: 0.95 }}
                    aria-label="Sonraki görsel"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-5 w-5" 
                      viewBox="0 0 20 20" 
                      fill="currentColor"
                    >
                      <path 
                        fillRule="evenodd" 
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" 
                        clipRule="evenodd" 
                      />
                    </svg>
                  </motion.button>
                </div>
              </div>
            </motion.div>

            {/* Sağ Taraf - Proje Detayları (1/3 Alan) */}
            <motion.div
              variants={itemVariants}
              className="bg-gray-800/70 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-700 shadow-xl shadow-glass p-6 flex flex-col relative"
            >
              {/* Dekoratif köşe elementleri */}
              <div className="absolute top-0 right-0 w-16 h-16 overflow-hidden">
                <div className="absolute top-0 right-0 w-8 h-8 translate-x-1/2 -translate-y-1/2 transform rotate-45 bg-gradient-to-br from-purple-500/30 to-blue-500/30"></div>
              </div>
              <div className="absolute bottom-0 left-0 w-16 h-16 overflow-hidden">
                <div className="absolute bottom-0 left-0 w-8 h-8 -translate-x-1/2 translate-y-1/2 transform rotate-45 bg-gradient-to-br from-teal-500/30 to-purple-500/30"></div>
              </div>
              <div className="flex-1">
                <div className="mb-6 bg-gray-900/50 p-4 rounded-lg backdrop-blur-sm detail-box">
                  <h2 className="text-lg font-semibold text-gray-300 mb-2">{t('projects.aboutProject', 'Proje Hakkında')}</h2>
                  <p className="text-gray-300">{typeof project?.longDescription === 'object' ? (project?.longDescription?.[currentLang] || project?.longDescription?.en || project?.longDescription?.tr || "") : project?.longDescription || ""}</p>
                </div>
                
                {/* Detay Bilgileri */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                  <div className="bg-gray-900/50 rounded-lg p-4 backdrop-blur-sm detail-box">
                    <h3 className="text-sm font-semibold text-blue-400 mb-2">{t('projects.developmentTime', 'Geliştirme Süresi')}</h3>
                    <p className="text-white">{typeof project?.details?.geliştirmeSüresi === 'object' ? (project?.details?.geliştirmeSüresi?.[currentLang] || project?.details?.geliştirmeSüresi?.en || project?.details?.geliştirmeSüresi?.tr || "-") : project?.details?.geliştirmeSüresi || "-"}</p>
                  </div>
                  <div className="bg-gray-900/50 rounded-lg p-4 backdrop-blur-sm detail-box">
                    <h3 className="text-sm font-semibold text-blue-400 mb-2">{t('projects.platform', 'Platform')}</h3>
                    <p className="text-white">{typeof project?.details?.platform === 'object' ? (project?.details?.platform?.[currentLang] || project?.details?.platform?.en || project?.details?.platform?.tr || "-") : project?.details?.platform || "-"}</p>
                  </div>
                  <div className="bg-gray-900/50 rounded-lg p-4 backdrop-blur-sm detail-box">
                    <h3 className="text-sm font-semibold text-blue-400 mb-2">{t('projects.status', 'Durum')}</h3>
                    <p className="text-white">{typeof project?.details?.durumu === 'object' ? (project?.details?.durumu?.[currentLang] || project?.details?.durumu?.en || project?.details?.durumu?.tr || "-") : project?.details?.durumu || "-"}</p>
                  </div>
                  <div className="bg-gray-900/50 rounded-lg p-4 backdrop-blur-sm detail-box">
                    <h3 className="text-sm font-semibold text-blue-400 mb-2">{t('projects.teamSize', 'Ekip Büyüklüğü')}</h3>
                    <p className="text-white">{typeof project?.details?.ekipBüyüklüğü === 'object' ? (project?.details?.ekipBüyüklüğü?.[currentLang] || project?.details?.ekipBüyüklüğü?.en || project?.details?.ekipBüyüklüğü?.tr || "-") : project?.details?.ekipBüyüklüğü || "-"}</p>
                  </div>
                </div>
                
                {/* Features Section */}
                {hasFeatures() && (
                  <motion.div
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    className="mb-8 bg-gray-900/50 p-4 rounded-lg backdrop-blur-sm detail-box"
                  >
                    <h3 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
                      <span className="text-yellow-400">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clipRule="evenodd" />
                        </svg>
                      </span>
                      {t('projects.features', 'Özellikler')}
                    </h3>
                    <motion.ul 
                      className="space-y-2"
                      variants={{
                        visible: {
                          transition: {
                            staggerChildren: 0.05
                          }
                        }
                      }}
                    >
                      {renderFeatures().map(({ id, content }) => (
                        <motion.li
                          key={id}
                          variants={{
                            hidden: { opacity: 0, x: -20 },
                            visible: { opacity: 1, x: 0 }
                          }}
                          className="flex items-start gap-2 text-gray-300"
                        >
                          <span className="text-yellow-400 mt-1">•</span>
                          <span>{content}</span>
                        </motion.li>
                      ))}
                    </motion.ul>
                  </motion.div>
                )}

                {/* Roles Section */}
                {hasRoles() && (
                  <motion.div
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    className="mb-8 bg-gray-900/50 p-4 rounded-lg backdrop-blur-sm detail-box"
                  >
                    <h3 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
                      <span className="text-blue-400">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                        </svg>
                      </span>
                      {t('projects.roles', 'Roller')}
                    </h3>
                    <motion.ul 
                      className="space-y-2"
                      variants={{
                        visible: {
                          transition: {
                            staggerChildren: 0.05
                          }
                        }
                      }}
                    >
                      {renderRoles().map(({ id, content }) => (
                        <motion.li
                          key={id}
                          variants={{
                            hidden: { opacity: 0, x: -20 },
                            visible: { opacity: 1, x: 0 }
                          }}
                          className="flex items-start gap-2 text-gray-300"
                        >
                          <span className="text-blue-400 mt-1">•</span>
                          <span>{content}</span>
                        </motion.li>
                      ))}
                    </motion.ul>
                  </motion.div>
                )}

                {/* Teknolojiler */}
                {project?.technologies && Array.isArray(project.technologies) && project.technologies.length > 0 && (
                  <div className="mb-6 bg-gray-900/50 p-4 rounded-lg backdrop-blur-sm detail-box">
                    <h3 className="text-sm font-semibold text-teal-400 mb-2 flex items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clipRule="evenodd" />
                      </svg>
                      {t('projects.technologies', 'Teknolojiler')}
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {project.technologies.map((tech, index) => (
                        <motion.span 
                          key={index}
                          className="bg-teal-600/30 text-teal-300 px-3 py-1 rounded-full text-xs border border-teal-500/30 tech-tag"
                          whileHover={{ y: -2 }}
                          animate={{ 
                            scale: [1, 1.03, 1],
                            rotate: [0, 0.5, 0, -0.5, 0]
                          }}
                          transition={{ 
                            repeat: Infinity, 
                            duration: 3 + index * 0.3,
                            delay: index * 0.1  
                          }}
                        >
                          {tech}
                        </motion.span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              
              {project.gameUrl && (
                <motion.a
                  href={project.gameUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 block md:hidden px-6 py-3 bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-500 hover:to-blue-500 rounded-lg text-white font-semibold transition-all duration-300 text-center play-button shadow-glass hover:shadow-glass-hover"
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div className="flex items-center justify-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                    </svg>
                    {t('projects.playGame', 'Oyunu Oyna')}
                  </div>
                </motion.a>
              )}
            </motion.div>
          </div>
          
          {/* Mobil görünümde oyunu oyna butonu */}
          {project.gameUrl && (
            <motion.div
              className="fixed bottom-6 right-6 z-50 md:hidden"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.5 }}
            >
              <motion.a
                href={project.gameUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-14 h-14 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white shadow-lg shadow-purple-500/30 glow-effect animate-float"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                </svg>
              </motion.a>
            </motion.div>
          )}
        </motion.div>
      )}
      
      {/* Özel scroll çubuğu için stil */}
      <style jsx global>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        
        @keyframes blob1 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          25% { transform: translate(10px, 10px) scale(1.1); }
          50% { transform: translate(0, 15px) scale(0.9); }
          75% { transform: translate(-10px, 5px) scale(1.05); }
        }
        
        @keyframes blob2 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          25% { transform: translate(-15px, 10px) scale(0.9); }
          50% { transform: translate(-5px, -10px) scale(1.1); }
          75% { transform: translate(10px, 0) scale(0.95); }
        }
        
        @keyframes blob3 {
          0%, 100% { transform: translate(0, 0) scale(1); }
          25% { transform: translate(10px, -15px) scale(1.05); }
          50% { transform: translate(5px, 10px) scale(0.95); }
          75% { transform: translate(-10px, -5px) scale(1.1); }
        }
        
        .animate-blob1 {
          animation: blob1 15s infinite ease-in-out;
        }
        
        .animate-blob2 {
          animation: blob2 18s infinite ease-in-out;
        }
        
        .animate-blob3 {
          animation: blob3 20s infinite ease-in-out;
        }
      `}</style>
    </section>
  );
}

export default ProjectDetail;