import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
    
    try {
      const form = e.target;
      const formData = new FormData(form);
      
      const encodedData = new URLSearchParams(formData).toString();
      
      const response = await fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encodedData,
      });
      
      if (response.ok) {
        setStatus('success');
        setFormData({ name: '', email: '', message: '' });
        setTimeout(() => setStatus(''), 3000);
        form.reset();
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Form submission error:', error);
      setStatus('error');
      setTimeout(() => setStatus(''), 3000);
    }
  };

  return (
    <section id="contact" className="md:min-h-screen flex items-center pb-32 md:pb-24 md:block">
      <div className="container mx-auto px-6 md:pt-16">
        <motion.h2 
          className="text-4xl md:text-6xl font-bold mb-8 md:mb-16 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400 leading-relaxed py-2"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          {t('contact.title')}
        </motion.h2>
        <div className="text-center mb-8 md:mb-12">
          <motion.p
            className="text-base md:text-lg text-gray-300"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <span dangerouslySetInnerHTML={{ __html: t('contact.directContact').replace('nuri.onacak@hotmail.com', '<a href="mailto:nuri.onacak@hotmail.com" class="text-purple-400 hover:text-purple-300 font-bold text-xl underline transition-colors">nuri.onacak@hotmail.com</a>') }} />
          </motion.p>
        </div>
        <div className="max-w-lg mx-auto px-4 md:px-0 mb-16 md:mb-0">
          <form 
            onSubmit={handleSubmit} 
            className="space-y-4 md:space-y-6"
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <div>
              <label className="block text-gray-300 mb-2">{t('contact.nameLabel')}</label>
              <input 
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder={t('contact.namePlaceholder')}
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-2">{t('contact.emailLabel')}</label>
              <input 
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder={t('contact.emailPlaceholder')}
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-2">{t('contact.messageLabel')}</label>
              <textarea 
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                rows="4"
                placeholder={t('contact.messagePlaceholder')}
              ></textarea>
            </div>
            <button 
              type="submit"
              disabled={status === 'sending'}
              className="w-full bg-purple-600 py-2 md:py-3 rounded-lg hover:bg-purple-700 transition disabled:opacity-50 mb-16 md:mb-0"
            >
              {status === 'sending' ? t('contact.sending') : t('contact.sendButton')}
            </button>
            
            {status === 'success' && (
              <p className="text-green-500 text-center">{t('contact.successMessage')}</p>
            )}
            
            {status === 'error' && (
              <p className="text-red-500 text-center">{t('contact.errorMessage')}</p>
            )}
          </form>
        </div>
        <div className="md:mt-16 text-center">
          <span className="inline-flex items-center gap-1 text-gray-600/50 text-sm font-medium">
            <span className="text-base">©</span> designed by IRUNBOSS
          </span>
        </div>
      </div>
    </section>
  );
}

export default Contact; 