import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Skills = () => {
  const { t } = useTranslation();
  const skills = [
    { name: "Unity", level: 70 },
    { name: "Unreal Engine", level: 50 },
    { name: "C#", level: 60 },
    { name: "C++", level: 30 },
    { name: "Game Design", level: 85 },
    { name: "Version Control", level: 85 },
  ];

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <section id="skills" className="min-h-screen flex items-center justify-center bg-gray-900 py-16 px-4">
      <div ref={ref} className="container mx-auto max-w-5xl">
        <motion.h2 
          className="text-3xl md:text-5xl font-bold mb-16 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 }
          }}
        >
          {t('skills.title')}
        </motion.h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-8">
          {skills.map((skill, index) => (
            <motion.div
              key={skill.name}
              className="bg-gray-800/50 backdrop-blur-sm p-4 md:p-6 rounded-xl shadow-lg"
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { 
                  opacity: 1, 
                  y: 0,
                  transition: { delay: index * 0.1 }
                }
              }}
            >
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-lg md:text-xl font-semibold text-gray-200">{skill.name}</h3>
                <span className="text-sm md:text-base text-primary-400 font-medium">{skill.level}%</span>
              </div>
              
              <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
                <motion.div
                  className="h-full bg-gradient-to-r from-primary-400 to-secondary-400"
                  initial={{ width: 0 }}
                  animate={controls}
                  variants={{
                    hidden: { width: 0 },
                    visible: { 
                      width: `${skill.level}%`,
                      transition: { delay: index * 0.1, duration: 0.8 }
                    }
                  }}
                />
              </div>
              
              <div className="flex mt-3 justify-center">
                {[...Array(5)].map((_, i) => (
                  <motion.div
                    key={i}
                    className={`w-2 md:w-3 h-2 md:h-3 rounded-full mx-1 ${
                      i < Math.floor(skill.level / 20) ? 'bg-primary-400' : 'bg-gray-600'
                    }`}
                    initial={{ scale: 0 }}
                    animate={controls}
                    variants={{
                      hidden: { scale: 0 },
                      visible: { 
                        scale: 1,
                        transition: { delay: index * 0.1 + i * 0.1 }
                      }
                    }}
                  />
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills; 