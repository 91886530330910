import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { socialLinks } from '../data/socialLinks';
import GameBackground from './GameBackground';
import { useLocation } from 'react-router-dom';

function Hero() {
  const location = useLocation();
  const isHomePage = location.pathname === '/' || location.pathname === '/home';
  const controls = useAnimation();
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.1,
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0, 
      y: 30,
      filter: "blur(10px)"
    },
    visible: { 
      opacity: 1, 
      y: 0,
      filter: "blur(0px)",
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const imageVariants = {
    hidden: { 
      scale: 0.9,
      opacity: 0,
      filter: "blur(10px)",
      y: 20
    },
    visible: { 
      scale: 1,
      opacity: 1,
      filter: "blur(0px)",
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };


  return (
    <section id="home" className="min-h-screen relative overflow-hidden bg-gradient-to-b from-gray-900 to-purple-900">
      {isHomePage && <GameBackground />}
      <div className="absolute inset-0 bg-grid-pattern bg-grid opacity-20"></div>
      
      <motion.div 
        ref={ref}
        className="flex flex-col items-center justify-center min-h-screen text-center"
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        <div className="flex flex-col items-center justify-center flex-grow">
          <motion.div 
            className="relative mb-8"
            variants={imageVariants}
          >
            <div className="w-48 h-48 md:w-56 md:h-56 rounded-full overflow-hidden border-4 border-blue-400/20 shadow-xl shadow-blue-500/10">
              <img 
                src="/images/profile.jpg" 
                alt="Nuri Onacak" 
                className="w-full h-full object-cover hover:scale-105 transition-all duration-500"
              />
            </div>
            <div className="absolute inset-0 rounded-full bg-gradient-to-b from-blue-500/10 to-transparent"></div>
          </motion.div>

          <motion.h1 
            className="text-5xl md:text-7xl font-bold mb-6 pt-4 pb-2 bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400 leading-relaxed"
            variants={itemVariants}
          >
            Nuri Onacak
          </motion.h1>
          
          <motion.div 
            className="space-y-4 mb-8"
            variants={itemVariants}
          >
            <motion.p 
              className="text-2xl md:text-3xl text-gray-300 font-medium tracking-wider"
            >
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400">
                {t('hero.profession')}
              </span>
            </motion.p>
          </motion.div>

          <motion.div 
            className="space-y-4"
            variants={itemVariants}
          >
            <motion.a
              href="#projects"
              className="inline-block px-6 py-3 bg-primary-500 rounded-full text-lg font-semibold hover:bg-primary-600 transition-all hover:scale-105 hover:shadow-lg hover:shadow-primary-500/30 mb-6"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('hero.cta')}
            </motion.a>
          </motion.div>

          <motion.p
            className="text-sm text-gray-400 italic mb-4 block"
            variants={itemVariants}
          >
            {t('hero.madeWith')}
          </motion.p>

          <motion.div 
            className="flex gap-4 mb-16 md:mb-24 lg:mb-24"
            variants={itemVariants}
          >
            {socialLinks.map((link, index) => {
              const Icon = link.icon;
              return (
                <a
                  key={link.url}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-2xl text-gray-400 transition-all duration-300 ${link.color} hover:scale-110`}
                >
                  <Icon />
                </a>
              );
            })}
          </motion.div>

          <motion.div 
            className="flex flex-col items-center text-gray-400 cursor-pointer group mb-8 md:mb-24"
            variants={itemVariants}
            onClick={() => {
              const aboutSection = document.getElementById('about');
              aboutSection?.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <span className="text-sm mb-2 group-hover:text-blue-400 transition-colors">
              {t('hero.scrollDown')}
            </span>
            <motion.div
              animate={{
                y: [0, 10, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-6 w-6 group-hover:text-blue-400 transition-colors"
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M19 14l-7 7m0 0l-7-7m7 7V3" 
                />
              </svg>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
}

export default Hero; 