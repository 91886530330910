export const projects = [
  {
    id: 1,
    title: {
      tr: "Lava Parkour: Muhittin's Adventure",
      en: "Lava Parkour: Muhittin's Adventure"
    },
    description: {
      tr: "Unreal Engine ile geliştirilmiş 3D platform oyunu. Lava parkour mücadelesi",
      en: "3D platform game developed with Unreal Engine. Lava parkour challenge"
    },
    image: "/images/Muhittin/0RwHN.jpg",
    date: "2024-04-30",
    technologies: ["Unreal Engine", "Blueprint", "3D Graphics"],
    gallery: [
      "/images/Muhittin/0RwHN.jpg",
      "/images/Muhittin/6oqZr.jpg"
    ],
    longDescription: {
      tr: "Lava Parkour: Muhittin's Adventure, muhittin adında bir karakterin, lava parkour mücadelesinde hayatta kalmaya çalıştığı bir oyundur.",
      en: "Lava Parkour: Muhittin's Adventure is a game where a character named Muhittin tries to survive in a lava parkour challenge."
    },
    videoUrl: "https://www.youtube.com/embed/wziPQBuIiQw",
    details: {
      geliştirmeSüresi: {
        tr: "1 hafta",
        en: "1 week"
      },
      platform: "PC",
      durumu: {
        tr: "Tamamlandı",
        en: "Completed"
      },
      ekipBüyüklüğü: {
        tr: "1 kişi",
        en: "1 person"
      },
      roller: {
        tr: ["Oyun Tasarımı", "Programlama", "UI/UX"],
        en: ["Game Design", "Programming", "UI/UX"]
      },
      özellikler: {
        tr: [],
        en: []
      }
    },
    gameUrl: "https://irunboss.itch.io/lavaparkour"
  },
  {
    id: 2,
    title: {
      tr: "Bum Bum Monsterz",
      en: "Bum Bum Monsterz"
    },
    description: {
      tr: "Unity ile geliştirilmiş 3D party oyunu. Bıngıl Bıngıl eğlence.",
      en: "3D party game developed with Unity. Bouncy fun."
    },
    image: "/images/Bum Bum Monsterz/Bumheader.jpg",
    date: "2024-11-27",
    technologies: ["Unity", "C#"],
    gallery: [
      "/images/Bum Bum Monsterz/Bum1.jpg",
      "/images/Bum Bum Monsterz/Bum2.jpg",
      "/images/Bum Bum Monsterz/Bum3.jpg",
      "/images/Bum Bum Monsterz/Bum4.jpg",
      "/images/Bum Bum Monsterz/Bum5.jpg",
    ],
    longDescription: {
      tr: "Bum Bum Monsterz, 2v2'den 4v4'e kadar oynanabilen takım tabanlı çok oyunculu bir parti oyunudur. Arkadaşlarınızı gırgır dolu mücadelelere girin, absürt engellerle başa çıkmaya çalışın ve eğlenceli bulmacalar çözün. Canlı grafikler ve renkli karakterlerle bıngıl bıngıl bir maceraya hazır mısınız?",
      en: "Bum Bum Monsterz is a team-based multiplayer party game that can be played from 2v2 to 4v4. Get into hilarious challenges with your friends, try to cope with absurd obstacles, and solve fun puzzles. Are you ready for a bouncy adventure with vibrant graphics and colorful characters?"
    },
    videoUrl: "https://www.youtube.com/embed/Z8L_kzEPoxg",
    details: {
      geliştirmeSüresi: {
        tr: "5 ay",
        en: "5 months"
      },
      platform: "PC",
      durumu: {
        tr: "Erken Erişim",
        en: "Early Access"
      },
      ekipBüyüklüğü: "-",
      roller: {
        tr: ["Bug Fix", "Programlama"],
        en: ["Bug Fix", "Programming"]
      },
      özellikler: {
        tr: [
          "Çoklu oyuncu desteği",
          "Özelleştirilebilir karakterler"
        ],
        en: [
          "Multiplayer support",
          "Customizable characters"
        ]
      }
    },
    gameUrl: "https://store.steampowered.com/app/2071400/Bum_Bum_Monsterz/"
  },
  {
    id: 3,
    title: {
      tr: "Dream Diver",
      en: "Dream Diver"
    },
    description: {
      tr: "Unity ile geliştirilmiş 3D FPS gamejam oyunu",
      en: "3D FPS gamejam game developed with Unity"
    },
    image: "/images/Dream Diver/1.jpg",
    date: "2023-08-27",
    technologies: ["Unity", "C#"],
    gallery: [
      "/images/Dream Diver/1.jpg",
      "/images/Dream Diver/2.jpg",
      "/images/Dream Diver/3.jpg",
      "/images/Dream Diver/4.jpg",
      "/images/Dream Diver/5.jpg",
      "/images/Dream Diver/6.jpg",
    ],
    longDescription: {
      tr: "Dream Diver, Konusu derine dalmak olan bir Brackeys Game Jam 2023.2 oyunudur. Oyunun amacı, rüyalarda derinlere dalarak düşmanları yok etmektir.",
      en: "Dream Diver is a Brackeys Game Jam 2023.2 game with the theme of diving deep. The goal of the game is to dive deep into dreams and eliminate enemies."
    },
    videoUrl: "https://www.youtube.com/embed/xSnDiETzB9I",
    details: {
      geliştirmeSüresi: {
        tr: "1 hafta",
        en: "1 week"
      },
      platform: "PC",
      durumu: {
        tr: "Tamamlandı",
        en: "Completed"
      },
      ekipBüyüklüğü: {
        tr: "3 kişi",
        en: "3 people"
      },
      roller: {
        tr: ["Oyun Tasarımı", "Programlama", "UI/UX", "Level Design"],
        en: ["Game Design", "Programming", "UI/UX", "Level Design"]
      },
      özellikler: {
        tr: [
          "FPS oyun mekanikleri",
          "Düşman yapay zekası"
        ],
        en: [
          "FPS game mechanics",
          "Enemy AI"
        ]
      }
    },
    gameUrl: "https://emircanisanc.itch.io/dream-diver"
  },
 /* {
    id: 4,
    title: "Racing Fever",
    description: "Unreal Engine ile geliştirilmiş yarış oyunu. Gerçekçi fizik motoru",
    image: "/images/racing-game.jpg",
    technologies: ["Unreal Engine", "C++", "Physics"],
    gallery: [
      "/images/racing-game/1.jpg",
      "/images/racing-game/2.jpg",
      "/images/racing-game/3.jpg",
      "/images/racing-game/4.jpg"
    ],
    longDescription: "Racing Fever, gerçekçi fizik motoru ile tasarlanmış bir yarış oyunudur. Oyuncular, farklı araçları kullanarak yarışları yaparken hız ve performansı optimize ederler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_4",
    details: {
      geliştirmeSüresi: "5 ay",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "3 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Fizik"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir araçlar"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 5,
    title: "Survival Island",
    description: "Unity ile geliştirilmiş hayatta kalma oyunu. Açık dünya macerası",
    image: "/images/survival-game.jpg",
    technologies: ["Unity", "C#", "AI"],
    gallery: [
      "/images/survival-game/1.jpg",
      "/images/survival-game/2.jpg",
      "/images/survival-game/3.jpg",
      "/images/survival-game/4.jpg"
    ],
    longDescription: "Survival Island, açık dünya macerası olarak tasarlanmış bir hayatta kalma oyunudur. Oyuncular, çeşitli zorluklarla karşılaşırken hayatta kalmayı amaçlar.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_5",
    details: {
      geliştirmeSüresi: "7 ay",
      platform: "PC, Mobile",
      durumu: "Devam ediyor",
      ekipBüyüklüğü: "4 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Yapay Zeka"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir karakterler"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 6,
    title: "Medieval Wars",
    description: "Unreal Engine ile geliştirilmiş strateji oyunu. Krallıklar arası savaş",
    image: "/images/strategy-game.jpg",
    technologies: ["Unreal Engine", "C++", "Multiplayer"],
    gallery: [
      "/images/strategy-game/1.jpg",
      "/images/strategy-game/2.jpg",
      "/images/strategy-game/3.jpg",
      "/images/strategy-game/4.jpg"
    ],
    longDescription: "Medieval Wars, krallıklar arası savaşı simüle eden bir strateji oyunudur. Oyuncular, farklı krallıkları kontrol ederken stratejiler geliştirirler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_6",
    details: {
      geliştirmeSüresi: "9 ay",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "5 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Çoklu Oyuncu"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir krallıklar"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 7,
    title: "Zombie Defense",
    description: "Unity ile geliştirilmiş tower defense oyunu. Zombi istilasına karşı savunma",
    image: "/images/zombie-game.jpg",
    technologies: ["Unity", "C#", "Pathfinding"],
    gallery: [
      "/images/zombie-game/1.jpg",
      "/images/zombie-game/2.jpg",
      "/images/zombie-game/3.jpg",
      "/images/zombie-game/4.jpg"
    ],
    longDescription: "Zombie Defense, zombi istilasına karşı savunma olarak tasarlanmış bir tower defense oyunudur. Oyuncular, zombileri engellemek ve koruma için çeşitli savunma yapıları inşa ederler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_7",
    details: {
      geliştirmeSüresi: "8 ay",
      platform: "PC, Mobile",
      durumu: "Devam ediyor",
      ekipBüyüklüğü: "3 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Yol Bulma"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir zombiler"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 8,
    title: "Sports League",
    description: "Unreal Engine ile geliştirilmiş spor simülasyonu. Gerçekçi fizik ve animasyonlar",
    image: "/images/sports-game.jpg",
    technologies: ["Unreal Engine", "C++", "Animation"],
    gallery: [
      "/images/sports-game/1.jpg",
      "/images/sports-game/2.jpg",
      "/images/sports-game/3.jpg",
      "/images/sports-game/4.jpg"
    ],
    longDescription: "Sports League, gerçekçi fizik ve animasyonlarla tasarlanmış bir spor simülasyonu oyunudur. Oyuncular, farklı sporları simüle ederken fizik ve animasyon becerilerini geliştirirler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_8",
    details: {
      geliştirmeSüresi: "7 ay",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "4 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Animasyon"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir sporlar"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 9,
    title: "City Builder",
    description: "Unity ile geliştirilmiş şehir inşa etme oyunu. Ekonomi ve kaynak yönetimi",
    image: "/images/city-game.jpg",
    technologies: ["Unity", "C#", "Economy System"],
    gallery: [
      "/images/city-game/1.jpg",
      "/images/city-game/2.jpg",
      "/images/city-game/3.jpg",
      "/images/city-game/4.jpg"
    ],
    longDescription: "City Builder, ekonomi ve kaynak yönetimi için tasarlanmış bir şehir inşa etme oyunudur. Oyuncular, şehirlerini inşa ederken ekonomi ve kaynak yönetimi hakkında bilgi edinirler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_9",
    details: {
      geliştirmeSüresi: "10 ay",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "5 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Ekonomi"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir şehirler"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 10,
    title: "VR Experience",
    description: "Unreal Engine ile geliştirilmiş sanal gerçeklik deneyimi. İnteraktif ortam",
    image: "/images/vr-game.jpg",
    technologies: ["Unreal Engine", "C++", "VR"],
    gallery: [
      "/images/vr-game/1.jpg",
      "/images/vr-game/2.jpg",
      "/images/vr-game/3.jpg",
      "/images/vr-game/4.jpg"
    ],
    longDescription: "VR Experience, sanal gerçeklik deneyimi olarak tasarlanmış bir oyunudur. Oyuncular, sanal ortamda interaktif deneyimler yaşarlar.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_10",
    details: {
      geliştirmeSüresi: "8 ay",
      platform: "PC, Mobile",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "3 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "VR"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir sanal gerçeklik"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  }*/
]; 