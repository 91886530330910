import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

function About() {
  const { t } = useTranslation();

  return (
    <section id="about" className="min-h-screen flex items-center justify-center bg-gray-900 px-4">
      <div className="container mx-auto max-w-4xl">
        <motion.h2 
          className="text-4xl md:text-5xl lg:text-6xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          {t('about.title')}
        </motion.h2>
        
        <motion.div 
          className="bg-gray-800/60 backdrop-blur-sm rounded-2xl p-8 md:p-10 shadow-xl"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.2 }}
        >
          <p className="text-xl text-gray-300 leading-relaxed text-center font-light">
            {t('about.description')}
          </p>
        </motion.div>
      </div>
    </section>
  );
}

export default About; 