import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [isEnglish, setIsEnglish] = useState(false);
  
  useEffect(() => {
    // i18n dil değişikliklerini dinle
    const handleLanguageChange = () => {
      const currentLang = i18n.language || 'tr';
      setIsEnglish(currentLang.startsWith('en'));
    };

    // Başlangıçta dil durumunu kontrol et
    handleLanguageChange();

    // Dil değişikliklerini dinle
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  return (
    <div className="relative flex items-center gap-2">
      {/* TR Label */}
      <span className={`text-xs font-bold ${!isEnglish ? 'text-white' : 'text-gray-400'}`}>
        TR
      </span>

      <motion.button
        onClick={() => changeLanguage(isEnglish ? 'tr' : 'en')}
        className="relative w-16 h-8 bg-gray-800/50 backdrop-blur-md rounded-full p-0.5 border border-gray-700/30 shadow-xl hover:shadow-purple-500/20 transition-all duration-300"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {/* Switch Track Background */}
        <div className="absolute inset-0 rounded-full overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-purple-900/50 to-blue-900/50" />
          <motion.div
            className="absolute inset-0 bg-gradient-to-br from-purple-600 to-blue-500"
            initial={false}
            animate={{
              opacity: 0.8
            }}
            transition={{ duration: 0.3 }}
          />
        </div>

        {/* Switch Knob - Joystick Style */}
        <motion.div
          className="relative z-10 w-7 h-7 rounded-full shadow-lg flex items-center justify-center"
          initial={{ x: isEnglish ? 32 : 0 }}
          animate={{
            x: isEnglish ? 32 : 0,
            rotate: isEnglish ? 180 : 0
          }}
          transition={{
            type: "spring",
            stiffness: 400,
            damping: 25
          }}
        >
          {/* Joystick Base */}
          <div className="absolute inset-0 rounded-full bg-gradient-to-b from-gray-800 to-gray-900 border border-gray-700"></div>
          
          {/* Joystick Top */}
          <div className="absolute inset-1 rounded-full bg-gradient-to-b from-gray-600 to-gray-700">
            <div className="absolute inset-1 rounded-full bg-gradient-to-b from-gray-400 to-gray-500 flex items-center justify-center">
              {/* Joystick Center Pattern */}
              <div className="w-3 h-3 rounded-full bg-gradient-to-br from-gray-800 to-gray-900 flex items-center justify-center">
                <div className="w-1.5 h-1.5 rounded-full bg-gradient-to-br from-gray-600 to-gray-700"></div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.button>

      {/* EN Label */}
      <span className={`text-xs font-bold ${isEnglish ? 'text-white' : 'text-gray-400'}`}>
        EN
      </span>
    </div>
  );
};

export default LanguageSwitcher; 