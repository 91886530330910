import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import trTranslation from './locales/tr.json';
import enTranslation from './locales/en.json';

// Dil kaynaklarını tanımlıyoruz
const resources = {
  tr: {
    translation: trTranslation
  },
  en: {
    translation: enTranslation
  }
};

i18n
  // Tarayıcı dilini otomatik algılamak için
  .use(LanguageDetector)
  // react-i18next ile entegrasyon için
  .use(initReactI18next)
  // Yapılandırma
  .init({
    resources,
    fallbackLng: 'en', // Varsayılan dil İngilizce
    debug: process.env.NODE_ENV === 'development',
    
    interpolation: {
      escapeValue: false, // React zaten XSS'ten koruyor
    },
    
    // Dil algılama seçenekleri
    detection: {
      order: ['navigator', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage'],
    }
  });

export default i18n; 