export const certificates = [
  {
    id: 1,
    title: "Unreal Engine Game Developer Program | UE01",
    issuer: "Skilled Hub",
    date: "2024",
    image: "/images/certificates/ue-certificate.jpg",
    link: "https://credential.certifyme.online/verify/3408010a15184",
    skills: ["Unreal Engine", "Blueprint", "C++"]
  },
  {
    id: 2,
    title: "Unity Game Development Fundamentals",
    issuer: "GameDev.tv",
    date: "2025",
    image: "/images/certificates/Udemy-FPS.jpg",
    link: "https://www.udemy.com/certificate/UC-de29d3dd-91e9-400c-a3be-3e52eb65efd6/",
    skills: ["Unreal Engine", "Blueprint","FPS", "Game Development"]
  }
]; 