import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { projects } from '../data/projects';
import { useTranslation } from 'react-i18next';

function Projects() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  
  const [currentPage, setCurrentPage] = useState(1);
  const [viewType, setViewType] = useState('grid'); // grid veya timeline görünümü için
  const projectsPerPage = window.innerWidth < 640 ? 6 : 6;
  
  // Projeleri tarihlerine göre sırala (en yeniden en eskiye)
  const sortedProjects = [...projects].sort((a, b) => {
    return new Date(b.date || '2000-01-01') - new Date(a.date || '2000-01-01');
  });
  
  // Zaman çizelgesi için projeleri eskiden yeniye sırala
  const timelineProjects = [...projects].sort((a, b) => {
    return new Date(a.date || '2000-01-01') - new Date(b.date || '2000-01-01');
  });
  
  const totalPages = Math.ceil(sortedProjects.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = sortedProjects.slice(indexOfFirstProject, indexOfLastProject);

  return (
    <section id="projects" className="min-h-screen py-8 md:py-16 bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.div className="text-center mb-12">
          <motion.h2 
            className="text-3xl md:text-6xl font-bold mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400 py-2"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            {t('projects.title')}
          </motion.h2>
          
          <motion.div
            className="flex flex-col items-center justify-center gap-2"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            <p className="text-gray-400 text-sm md:text-base">
              {t('projects.clickForDetails', 'Click on projects for detailed information')}
            </p>
            
            {/* Görünüm tipi değiştirme butonları */}
            <div className="flex space-x-4 mt-4 mb-6">
              <button 
                onClick={() => setViewType('grid')}
                className={`px-4 py-2 text-sm rounded-full transition-all ${viewType === 'grid' 
                  ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white' 
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'}`}
              >
                {t('projects.gridView', 'Izgara Görünüm')}
              </button>
              <button 
                onClick={() => setViewType('timeline')}
                className={`px-4 py-2 text-sm rounded-full transition-all ${viewType === 'timeline' 
                  ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white' 
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'}`}
              >
                {t('projects.timelineView', 'Zaman Çizelgesi')}
              </button>
            </div>
            
            <motion.div
              animate={{
                y: [0, 5, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5 text-purple-400"
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M19 14l-7 7m0 0l-7-7m7 7V3"
                />
              </svg>
            </motion.div>
          </motion.div>
        </motion.div>
        
        {viewType === 'grid' ? (
          // Izgara Görünümü
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-6 max-w-7xl mx-auto">
            {currentProjects.map((project, index) => (
              <motion.div
                key={project.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                onClick={() => window.location.href = `/project/${project.id}`}
                className="group relative bg-gray-800 rounded-xl overflow-hidden hover:shadow-2xl hover:shadow-purple-500/20 transition-all duration-300 cursor-pointer"
              >
                <div className="relative h-24 sm:h-36 overflow-hidden">
                  <img 
                    src={project.image} 
                    alt={typeof project.title === 'object' ? (project.title[currentLang] || project.title.en || project.title.tr) : project.title} 
                    className="w-full h-full object-cover object-center transform group-hover:scale-110 transition-transform duration-300"
                    onError={(e) => {
                      e.target.src = '/images/placeholder.jpg'; // Resim yüklenemezse placeholder göster
                      e.target.onerror = null; // Sonsuz döngüyü engelle
                    }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-70"></div>
                </div>
                
                <div className="p-2 md:p-4">
                  <h3 className="text-xs md:text-lg font-bold mb-1 md:mb-2 text-white truncate">
                    {typeof project.title === 'object' ? (project.title[currentLang] || project.title.en || project.title.tr) : project.title}
                  </h3>
                  <p className="text-[8px] md:text-sm text-gray-300 mb-1 line-clamp-2 hidden sm:block">
                    {typeof project.description === 'object' ? (project.description[currentLang] || project.description.en || project.description.tr) : project.description}
                  </p>
                  <div className="flex justify-between items-center">
                    <div className="flex flex-wrap gap-0.5">
                      {project.technologies.slice(0, 1).map((tech) => (
                        <span 
                          key={tech}
                          className="bg-purple-600/30 text-purple-300 px-1 py-0.5 rounded-full text-[8px] md:text-xs border border-purple-500/30"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                    {project.date && (
                      <span className="text-[8px] md:text-xs text-gray-400">
                        {new Date(project.date).toLocaleDateString(i18n.language === 'tr' ? 'tr-TR' : 'en-US', {
                          year: 'numeric',
                          month: 'long'
                        })}
                      </span>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        ) : (
          // Zaman Çizelgesi Görünümü
          <div className="max-w-5xl mx-auto">
            <div className="relative pt-2 pb-12">
              {/* Orta çizgi */}
              <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-blue-500/30"></div>
              
              {timelineProjects.map((project, index) => (
                <motion.div 
                  key={project.id}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className={`relative mb-8 flex items-center ${
                    index % 2 === 0 ? 'justify-end md:justify-start' : 'justify-end'
                  }`}
                >
                  {/* Tarih balonu */}
                  <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 z-10">
                    <div className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-1 px-3 rounded-full text-xs shadow-lg whitespace-nowrap">
                      {project.date 
                        ? new Date(project.date).toLocaleDateString(i18n.language === 'tr' ? 'tr-TR' : 'en-US', {
                            year: 'numeric',
                            month: 'long'
                          }) 
                        : t('projects.noDate', 'Tarih Yok')
                      }
                    </div>
                  </div>
                  
                  {/* İçerik kartı */}
                  <div 
                    className={`w-5/12 md:w-4/12 group bg-gray-800 rounded-xl overflow-hidden hover:shadow-2xl hover:shadow-purple-500/20 transition-all duration-300 cursor-pointer ${
                      index % 2 === 0 ? 'md:mr-auto md:ml-12' : 'ml-auto md:mr-12'
                    }`}
                    onClick={() => window.location.href = `/project/${project.id}`}
                  >
                    <div className="relative aspect-video overflow-hidden">
                      <img 
                        src={project.image} 
                        alt={typeof project.title === 'object' ? (project.title[currentLang] || project.title.en || project.title.tr) : project.title} 
                        className="w-full h-full object-cover object-center transform group-hover:scale-110 transition-transform duration-300"
                        onError={(e) => {
                          e.target.src = '/images/placeholder.jpg'; // Resim yüklenemezse placeholder göster
                          e.target.onerror = null; // Sonsuz döngüyü engelle
                        }}
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-70"></div>
                    </div>
                    
                    <div className="p-3">
                      <h3 className="text-base font-bold mb-1 text-white truncate">{typeof project.title === 'object' ? (project.title[currentLang] || project.title.en || project.title.tr) : project.title}</h3>
                      <p className="text-xs text-gray-300 mb-1 line-clamp-2">{typeof project.description === 'object' ? (project.description[currentLang] || project.description.en || project.description.tr) : project.description}</p>
                      <div className="flex flex-wrap gap-1 mt-1">
                        {project.technologies.slice(0, 2).map((tech) => (
                          <span 
                            key={tech}
                            className="bg-purple-600/30 text-purple-300 px-1.5 py-0.5 rounded-full text-[10px] border border-purple-500/30"
                          >
                            {tech}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        )}

        {viewType === 'grid' && totalPages > 1 && (
          <div className="flex justify-center gap-2 mt-6">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 ${
                  currentPage === index + 1
                    ? 'bg-gradient-to-r from-blue-400 to-teal-400 text-white shadow-lg shadow-blue-500/30'
                    : 'bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-blue-400'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Projects; 